import React from "react";
import { useClasses, ITheme, Block, Col, Row } from "@maxeb/admin-ui";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";

import logo from "../../assets/images/Logo.svg";

export interface IProps {
  name?: string;
  subscript?: string;
  icon?: IconDefinition;
  thumb?: {
    hash: string;
    name: string;
    url: string;
  };
}
const styles = (theme: ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.get("tertiary"),
    height: "50px",
    color: "#fff",
  },
  logo: { height: "44px", marginTop: "3px", display: "inline-block" },
  text: { fontSize: "1.7rem", lineHeight: "50px" },
});
const Header: React.FC<IProps> = (props) => {
  const classes = useClasses(styles, props);

  return (
    <Row override={{ row: classes.wrapper }} root={0}>
      <Col xs={"50px"}>
        <img className={classes.logo} src={logo} alt="webcollector-logo" />{" "}
      </Col>
      <Col xs="rest" override={{ col: classes.text }}>
        <span>Webcollector</span>
      </Col>
    </Row>
  );
};

export default Header;
