import React from "react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, ITheme, useClasses, Typo } from "@maxeb/admin-ui";
import useArtworks from "../hooks/Artworks";
//import useCategories from "../hooks/Categories";
import Artwork from "./Artwork";
import Card from "./shared/Card";
import IsVisble from "./shared/IsVisble";

const styles = (theme: ITheme) => ({
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
});

function Artworks() {
  const [state, actions] = useArtworks();
  //const [categies] = useCategories();

  const classes = useClasses(styles);

  return (
    <Container>
      <Row
        spacing={16}
        vertical
        root={{ top: 32, left: 0, right: 0, bottom: 32 }}
      >
        {state.data.length === 0 &&
          (state.status === "ok" || state.status === "error") && (
            <div>
              <Typo variant="h1">Nicht Gefunden!</Typo>
              <Typo variant="p">
                Zu dieser Sammlung konnten keine Werke gefunden werden.
                <br />
                Möglicherweise ist die Sammlung nicht öffentlich oder existiert
                nicht.
              </Typo>
            </div>
          )}
        {state.data.map((artwork) => {
          return (
            <Col
              key={`artwork-${artwork.id}`}
              xs={10}
              sm={5}
              md={10 / 3}
              lg={10 / 4}
              xl={10 / 5}
            >
              <div
                onClick={() => {
                  if (artwork.id) actions.setActive(artwork.id);
                }}
              >
                <Card
                  name={artwork.artist}
                  subscript={artwork.title}
                  /* icon={faPaintBrush} */
                  thumb={
                    artwork.thumb && {
                      ...artwork.thumb,
                      url: artwork.thumb["xs-url"],
                    }
                  }
                />
              </div>
            </Col>
          );
        })}
        {state.status === "pending" && (
          <Col
            xs={10}
            sm={5}
            md={10 / 3}
            lg={10 / 4}
            xl={10 / 5}
            override={{ col: classes.pending }}
          >
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Col>
        )}
        <Col xs={10} sm={5} md={10 / 3} lg={10 / 4} xl={10 / 5}>
          <IsVisble
            onChange={(isVisible) => {
              if (isVisible && state.status === "ok" && state.next)
                actions.next();
            }}
          />
        </Col>
      </Row>
      {state.active && (
        <Artwork
          id={state.active}
          onClose={function (): void {
            actions.setActive(null);
          }}
          thumbStatus={"ok"}
          mockupStatus={"ok"}
        />
      )}
    </Container>
  );
}

export default Artworks;
