import React from "react";
import "./App.css";
import Artworks from "./components/Artworks";
import Header from "./components/shared/Header";

function App() {
  return (
    <>
      <Header />
      <Artworks />
    </>
  );
}

export default App;
