import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { options as ArtOptions } from "@maxeb/art-sdk";
import { init } from "@maxeb/admin-ui";

ArtOptions.setApiKey("ks4zlMK0DZ8hX0nub9Uv38n4oxGnbq2M7CHtKkTQ");
ArtOptions.setUrl("https://art.webcollector.at");

const palette = {
  primary: "#5c90d2",
  secondary: "#2c689c",
  tertiary: "#212a37",
  danger: "#b7433f",
  success: "#559554",
  warning: "#e8db05",
  default: "#ececec",
  grey: "#e0e0e0",
  darkgrey: "#525252",
  font: "#3e3e3e",
  background: "#ececec",
  formLabel: "#797979",
  formBackground: "#ececec",
  formBackgroundActive: "#e0e0e0",
  mediumGrey: "#a8a8a8",
  section: "#717171",
};

init({ palette: palette });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
