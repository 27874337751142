import { Artwork, IArtwork } from "@maxeb/art-sdk";
import { useEffect, useState } from "react";
import { getTeamAndCollection } from "./Artworks";

export interface IState {
  status: "ok" | "init" | "pending" | "error";
  id: string;
  data?: IArtwork;
}
export interface IActions {}

let MOUNTED = false;

async function get(state: IState, setState: (state: IState) => void) {
  setState({ ...state, status: "pending" });
  try {
    const { team, collection } = getTeamAndCollection();
    const response = await Artwork.getPublic(
      {
        id: state.id,
        collection,
        team,
      },
      [
        "id",
        "title",
        "size",
        "height",
        "width",
        "depth",
        "year",
        "categoryId",
        "techniqueId",
        "artist",
        "mockup",
        "thumb",
        "collection",
        "team",
        "signed",
        "frame",
        "sellingPrice",
        "available",
      ],
      1
    );

    if (response.isSuccess()) {
      const result = response.getResult();
      return setState({
        ...state,
        status: "ok",
        data: result.data[0],
      });
    }
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}

export default function (id: string): [IState, IActions] {
  const [state, setState] = useState<IState>({
    status: "init",
    id,
  });

  const setStateSave = (newState: IState) => {
    if (MOUNTED) setState(newState);
  };

  useEffect(() => {
    MOUNTED = true;
    if (state.status === "init") get(state, setStateSave);

    return () => {
      MOUNTED = false;
    };
  });

  return [state, {}];
}
