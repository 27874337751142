import { Artwork, IArtwork } from "@maxeb/art-sdk";
import { useEffect, useState } from "react";

export interface IState {
  status: "ok" | "init" | "pending" | "error";
  data: IArtwork[];
  active: string | null;
  team: string;
  collection: string;
  next?: string;
}
export interface IActions {
  next: () => void;
  setActive: (active: string | null) => void;
}

let MOUNTED = false;

async function next(state: IState, setState: (state: IState) => void) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Artwork.getPublic(
      {
        collection: state.collection,
        team: state.team,
      },
      [
        "id",
        "title",
        "artist",
        "thumb",
        "collection",
        "team",
        "sellingPrice",
        "categoryId",
        "techniqueId",
        "available",
      ],
      1000,
      "secondaryIndex",
      "ASC"
    );

    if (result.isSuccess())
      return setState({
        ...state,
        status: "ok",
        data: [...state.data, ...result.body.data],
        next: result.body.next,
      });
  } catch (err) {
    console.error(err);
  }
  setState({ ...state, status: "error" });
}

export function getTeamAndCollection() {
  const urlString = window.location.href || "";
  const url = new URL(urlString);

  const team = url.searchParams.get("t") || "";
  const collection = url.searchParams.get("c") || "";

  return { team, collection };
}

export default function (): [IState, IActions] {
  const [state, setState] = useState<IState>({
    status: "init",
    data: [],
    active: null,
    ...getTeamAndCollection(),
  });

  const setStateSave = (newState: IState) => {
    if (MOUNTED) setState(newState);
  };

  useEffect(() => {
    MOUNTED = true;
    if (state.status === "init") next(state, setStateSave);

    return () => {
      MOUNTED = false;
    };
  });

  return [
    state,
    {
      next: () => next(state, setStateSave),
      setActive: (active: string | null) => setStateSave({ ...state, active }),
    },
  ];
}
