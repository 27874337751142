import React from "react";
import { useClasses, ITheme, Block } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import Image from "./ImageLoader";

export interface IProps {
  name?: string;
  subscript?: string;
  icon?: IconDefinition;
  thumb?: {
    hash: string;
    name: string;
    url: string;
  };
}
const styles = (theme: ITheme) => ({
  wrapper: {
    cursor: "pointer",
  },
  text: {
    padding: "8px 16px",
    width: "100%",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  name: {
    color: "black",
    fontSize: "1.5rem",
  },
  subscript: {
    color: theme.palette.get("darkGrey"),
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  container: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
  },
  img: {
    position: "absolute !important",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100% !important",
    height: "100% !important",
    objectFit: "cover",
  },
  noThumb: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: "calc(50% - 4rem)",
    backgroundColor: theme.palette.get("default"),
    textAlign: "center",
    boxSizing: "border-box",
    fontSize: "6rem",
    color: theme.palette.get("grey"),
  },
});
const Card: React.FC<IProps> = (props) => {
  const { name, subscript, thumb } = props;
  const classes = useClasses(styles, props);

  return (
    <div className={classes.wrapper}>
      <Block padding={0}>
        <div className={classes.container}>
          {!thumb && (
            <div className={classes.noThumb}>
              {props.icon && (
                <FontAwesomeIcon
                  className={classes.noThumbIcon}
                  icon={props.icon}
                />
              )}
            </div>
          )}
          {thumb && (
            <Image
              className={classes.img}
              src={thumb.url}
              hash={thumb.hash}
              alt={thumb.name}
            />
          )}
        </div>

        <div className={classes.text}>
          <span className={classes.name}>{name}</span>
          {Boolean(subscript) && (
            <>
              <br />
              <span className={classes.subscript}>{subscript}</span>
            </>
          )}
        </div>
      </Block>
    </div>
  );
};

export default Card;
