import React from "react";
import {
  useClasses,
  ITheme,
  Row,
  Col,
  Button,
  Form,
  Block,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

import classnames from "classnames";
import LightBox from "./shared/Lightbox";
import useArtwork from "../hooks/Artwork";

import ImageLoader from "./shared/ImageLoader";

export interface IProps {
  onClose: () => void;
  id: string;
  available?: number;
  artist?: string;
  title?: string;
  size?: string;
  price?: string;
  thumbUrl?: string;
  thumb?: string | null;
  year?: number | null;
  category?: string | null;
  technique?: string | null;
  description?: string;
  artistDescription?: string | null;
  thumbStatus: "pending" | "ok" | "none";
  mockup?: string | null;
  mockupStatus: "pending" | "ok" | "none";
}

const styles = (theme: ITheme) => ({
  alignment: {
    marginTop: "63px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "8px",
    },
  },
  wrapper: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    right: "-12px",
    top: "-6px",
    cursor: "pointer",
  },
  pendingImg: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  mock: {
    maxWidth: "100%",
  },
  thumb: {
    maxWidth: "500px",
  },
  primary: {
    color: theme.palette.get("primary"),
  },
  badge: {
    padding: "2px 8px",
    display: "inline-block",
    borderRadius: "4px",
  },
  badgePrimary: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff",
  },
  badgeSuccess: {
    backgroundColor: theme.palette.get("success"),
    color: "#fff",
  },
  badgeWarning: {
    backgroundColor: theme.palette.get("warning"),
    color: "#fff",
  },
  badgeDanger: {
    backgroundColor: theme.palette.get("danger"),
    color: "#fff",
  },
  grey: {
    color: theme.palette.get("darkgrey"),
    fontSize: "0.8rem",
  },
  blackBox: {
    backgroundColor: "#000",
    color: "#fff",
    margin: "0px -16px -16px -16px",
    padding: "16px",
  },
  artistHeadline: {
    marginTop: "0px",
  },
  artistPortraitIcon: {
    backgroundColor: theme.palette.get("default"),
    color: theme.palette.get("grey"),
    textAlign: "center",
    fontSize: "6rem",
    padding: "16px",
    borderRadius: "100%",
  },
  actions: {
    position: "absolute",
    top: "8px",
    left: "0px",
    right: "0px",
    [theme.breakpoints.down("lg")]: {
      top: "0px",
    },
  },
  actionsPlaceholder: {
    height: (props: IProps) => (props.mockupStatus === "none" ? "56px" : "0px"),
    [theme.breakpoints.down("lg")]: {
      height: "46px",
    },
  },
  pending: {
    padding: "32px 0px",
    textAlign: "center",
    fontSize: "5rem",
    color: theme.palette.get("primary"),
  },
});

function getSize(
  width: number | undefined,
  height: number | undefined,
  depth: number | undefined
) {
  let buffer = "";
  if (width !== undefined) buffer += width;
  if (width !== undefined && height !== undefined) buffer += "x";
  if (height !== undefined) buffer += height;
  if (height !== undefined && depth !== undefined) buffer += "x";
  if (depth !== undefined) buffer += depth;

  if (buffer.length) return buffer + "cm";
  else return "";
}

function ArtworkDetails(props: IProps) {
  const classes = useClasses(styles, props);

  const [state, setState] = useArtwork(props.id);
  const artwork = state.data || {};
  const available = artwork.available;

  return (
    <LightBox>
      <div className={classes.alignment}>
        <Row spacing={16} vertical root={0}>
          <Block>
            <div className={classes.wrapper}>
              <div className={classes.actions}>
                <Row spacing={8} root={0}>
                  <Col xs="rest"></Col>
                  <Button xs="40px" danger onClick={props.onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Row>
              </div>
              <div className={classes.actionsPlaceholder} />
              {(state.status === "init" || state.status === "pending") && (
                <div className={classes.pending}>
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                </div>
              )}
              {!(state.status === "init" || state.status === "pending") && (
                <Row spacing={16} vertical root={0} verticalAlign="bottom">
                  {artwork.mockup && (
                    <Col lg={2}>
                      <ImageLoader
                        className={classes.mock}
                        src={artwork.mockup.url}
                        alt={artwork.title || ""}
                      />
                    </Col>
                  )}
                  <Col lg="auto">
                    {artwork.thumb && (
                      <ImageLoader
                        className={classes.thumb}
                        src={artwork.thumb.url}
                        alt={artwork.title || ""}
                      />
                    )}
                  </Col>
                  <Col lg="rest-fixed">
                    <Row
                      spacing={8}
                      vertical
                      root={{ top: 8, bottom: 0, left: 0, right: 0 }}
                    >
                      <Col xs={10}>
                        <div className={classes.primary}>{artwork.artist}</div>
                        <div className={classes.grey}>{artwork.title}</div>
                      </Col>
                      {artwork.size && (
                        <Col sm={5}>
                          <div className={classes.grey}>
                            {getSize(
                              artwork.width,
                              artwork.height,
                              artwork.depth
                            )}
                          </div>
                        </Col>
                      )}
                      {artwork.year && (
                        <Col sm={5}>
                          <div className={classes.grey}>{artwork.year}</div>
                        </Col>
                      )}
                      {/*                       {artwork.categoryId && (
                        <Col sm={5}>
                          <Category id={artwork.categoryId} />
                        </Col>
                      )}
                      {artwork.techniqueId && (
                        <Col sm={5}>
                          <div className={classes.grey}>
                            {artwork.techniqueId}
                          </div>
                        </Col>
                      )} */}
                      {available === 0 && (
                        <span
                          className={classnames([
                            classes.badge,
                            classes.badgeDanger,
                          ])}
                        >
                          Verkauft
                        </span>
                      )}
                      {available === 1 && (
                        <span
                          className={classnames([
                            classes.badge,
                            classes.badgeWarning,
                          ])}
                        >
                          Auf Anfrage
                        </span>
                      )}
                      {available === 2 && Boolean(artwork.sellingPrice) && (
                        <span
                          className={classnames([
                            classes.badge,
                            classes.badgeSuccess,
                          ])}
                        >
                          {artwork.sellingPrice}€
                        </span>
                      )}
                      {artwork.description && (
                        <Col>
                          {artwork.description
                            .split("<br>")
                            .map((item, index) => (
                              <>
                                {item}
                                <br />
                              </>
                            ))}
                        </Col>
                      )}

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Row
                          spacing={8}
                          vertical
                          root={{ top: 16, left: 0, right: 0, bottom: 0 }}
                          horizontalAlign="right"
                        >
                          <Button xs="110px" onClick={props.onClose}>
                            Schließen
                          </Button>
                        </Row>
                      </Form>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </Block>
        </Row>
      </div>
    </LightBox>
  );
}

export default ArtworkDetails;
